<template>
  <main>
  <nav class="nav">
    <div class="router-links-container">
      <router-link to="/" class="nav-link">home</router-link>
      <!-- <router-link to="/teaser" class="nav-link">teaser</router-link> -->
      <router-link to="/press" class="nav-link">press</router-link>
      <router-link to="/podcast" class="nav-link">podcast</router-link>
      <router-link to="/contact" class="nav-link">contact</router-link>
      <div class="social-media-links-container">
        <a href="https://www.facebook.com/people/Creature-Fear-Film/61555271544540/" target="_blank" class="social-media-link"><img class="fb-logo" src="./assets/facebook.png" alt="Facebook"></a>
        <a href="https://www.instagram.com/creaturefearfilm/" target="_blank" class="social-media-link"><img class="fb-logo" src="./assets/instagram.png" alt="Facebook"></a>
        <a href="https://fundraising.fracturedatlas.org/creature-fear" target="_blank"><img class="donate-img" alt="Donate now at Fractured Atlas!" src="https://fundraising.fracturedatlas.org/images/fundraiser-share-buttons/share-button--dark--alpha.png" /></a>
      </div>
    </div>
  </nav>
  <div class="hamburger-container">
    <div class="hamburger" id="hamburger" @click="toggleHamburger">
      <span class="bar" v-bind:class="{ turned: hamburgerToggled }"></span>
      <span class="bar" v-bind:class="{ turned: hamburgerToggled }"></span>
      <span class="bar" v-bind:class="{ turned: hamburgerToggled }"></span>
    </div>
  </div>
  <nav class="mobile-nav">
    <div class="mobile-nav-links" v-bind:class="{ visible: hamburgerToggled }">
      <div class="mobile-nav-link-container">
        <router-link to="/" class="nav-link mobile" @click="toggleHamburger">home</router-link>
      </div>
      <!-- <router-link to="/teaser" class="nav-link mobile" @click="toggleHamburger">teaser</router-link> -->
      <div class="mobile-nav-link-container">
        <router-link to="/press" class="nav-link mobile" @click="toggleHamburger">press</router-link>
      </div>
      <div class="mobile-nav-link-container">
        <router-link to="/podcast" class="nav-link mobile" @click="toggleHamburger">podcast</router-link>
      </div>
      <div class="mobile-nav-link-container">
        <router-link to="/contact" class="nav-link mobile" @click="toggleHamburger">contact</router-link>
      </div>
      <div class="social-media-links-container mobile">
        <a href="https://www.facebook.com/people/Creature-Fear-Film/61555271544540/" target="_blank" class="social-media-link"><img class="fb-logo" src="./assets/facebook.png" alt="Facebook"></a>
        <a href="https://www.instagram.com/creaturefearfilm/" target="_blank" class="social-media-link"><img class="fb-logo" src="./assets/instagram.png" alt="Facebook"></a>
        <div class="donate-link-mobile-container">
          <a class="donate-link-mobile" href="https://fundraising.fracturedatlas.org/creature-fear" target="_blank"><img class="donate-img mobile-dontate-img" alt="Donate now at Fractured Atlas!" src="https://fundraising.fracturedatlas.org/images/fundraiser-share-buttons/share-button--dark--alpha.png" /></a>
        </div>
      </div>
    </div>
  </nav>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component">
        </component>
      </transition>
    </router-view>
  </main>

  <FooterComponent />
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import FooterComponent from './views/FooterView.vue'; // Adjust path as necessary

  const isNavFixed = ref(false);

  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });

  const handleScroll = () => {
    isNavFixed.value = window.pageYOffset > 0;
  };

  let hamburgerToggled = ref(false);

  let toggleHamburger = () => {
    hamburgerToggled.value = !hamburgerToggled.value
  }
</script>

<style lang="scss">
@import './assets/styles/styles.scss';
#app {
  font-family: IM Fell Double Pica, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// .fade-enter-active, .fade-leave-active {
//   transition-property: opacity;
//   transition-duration: .25s;
// }

// .fade-enter, .fade-leave-active {
//   opacity: 0
// }

.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.15s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: all 0.15s ease-in;
}
</style>
