<template>
    <div class="contact container-div">
        <h1 class="small-h1 contact-h1">Get In Touch</h1>
        <p class="contact-p">Fill out the form below and we'll get back to you as soon as possible.</p>
        <!-- modify this form HTML and place wherever you want your form -->
        <form
        action="https://formspree.io/f/xdoqjdke"
        method="POST"
        class="contact-form"
        >
        <label>
            Your email:
            <input type="email" name="email">
        </label>
        <label>
            Your message:
            <textarea name="message"></textarea>
        </label>
        <!-- your other form fields go here -->
        <button type="submit">SEND</button>
        </form>
    </div>
</template>

<script>
</script>