<template>
    <div class="trailer container-div">
        <h1 class="small-h1">Watch the Creature Fear Official Teaser</h1>
        <div class="video-container">
            <iframe class="video" src="https://www.youtube.com/embed/tWz65SYYiH4?si=bVLGcG1-963en9r_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
</script>