<template>
    <div class="gallery container-div">
        <h1 class="small-h1 press-h1">For the Press</h1>
        <p class="press-p">Download the Creature Fear Press Notes <a href="/CREATURE FEAR Press Notes.pdf" target="_blank" class="press-notes-link">here</a></p>
        <div class="gallery-row">
            <img class="gallery-img" src="../assets/gallery/still-1.jpg" alt="still-1">
            <img class="gallery-img" src="../assets/gallery/still-2.jpeg" alt="still-2">
        </div>
        <div class="gallery-row">
            <img class="gallery-img" src="../assets/gallery/still-3.jpeg" alt="still-3">
            <img class="gallery-img" src="../assets/gallery/still-4.jpeg" alt="still-4">
        </div>
        <div class="gallery-row">
            <img class="gallery-img" src="../assets/gallery/still-5.jpeg" alt="still-5">
            <img class="gallery-img" src="../assets/gallery/still-6.jpeg" alt="still-6">
        </div>
        <div class="gallery-row">
            <img class="gallery-img" src="../assets/gallery/still-7.jpeg" alt="still-7">
            <img class="gallery-img" src="../assets/gallery/still-8.jpeg" alt="still-8">
        </div>
        <div class="gallery-row">
            <img class="gallery-img" src="../assets/gallery/still-9.jpeg" alt="still-9">
            <img class="gallery-img" src="../assets/gallery/still-10.jpeg" alt="still-10">
        </div>
        <div class="gallery-row">
            <img class="gallery-img" src="../assets/gallery/still-11.jpeg" alt="still-11">
            <img class="gallery-img" src="../assets/gallery/still-12.jpeg" alt="still-12">
        </div>
        <div class="gallery-row">
            <img class="gallery-img" src="../assets/gallery/still-13.jpeg" alt="still-13">
            <img class="gallery-img" src="../assets/gallery/still-14.jpeg" alt="still-14">
        </div>
    </div>
</template>

<script setup>
</script>