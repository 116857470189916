<template>
    <div class="container-div">
        <h1 class="small-h1">That page wasn't found.</h1>
        <router-link class="not-found-link" to="/">
            <button class="not-found-button">
                GO HOME
            </button>
        </router-link>
    </div>
</template>

<script>
</script>