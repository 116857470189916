<template>
  <div class="podcasts container-div">
    <h1 class="small-h1">Creature Fear Podcast</h1>
    <div class="podcast-div">
      <div v-for="(item) in podcastItems" :key="item.guid">
        <h3 class="podcast-episode-title">{{ item.title }}</h3>
        <audio controls>
          <source :src="item.enclosure.url" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
      </div>
      <div v-if="platformLinks">
        <div v-if="platformLinks.spotify">
          <a :href="platformLinks.spotify" target="_blank" class="podcast-platform-link">
            Listen on
            <img src="../assets/spotify.png">
          </a>
        </div>
        <!-- <div v-if="platformLinks.apple"><a :href="platformLinks.apple" target="_blank">Listen on Apple Podcasts</a></div> -->
        <!-- Add more platforms as needed -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import xml2js from 'xml2js';

export default {
  data() {
    return {
      podcastItems: [],
      platformLinks: {}
    };
  },
  created() {
    this.fetchPodcast();
  },
  methods: {
    async fetchPodcast() {
      try {
        const response = await axios.get('https://anchor.fm/s/f6049b8c/podcast/rss');
        const parser = new xml2js.Parser();
        parser.parseString(response.data, (err, result) => {
          if (err) {
            console.error(err);
            return;
          }
          const channel = result.rss.channel[0];
          const items = channel.item;
          const podcastItems = items.map(item => ({
            title: item.title[0],
            description: item.description[0],
            enclosure: {
              url: item.enclosure[0].$.url
            },
            guid: item.guid[0]
          }));
          this.podcastItems = podcastItems;

          // Extract platform links
          const platformLinks = {
            spotify: channel.link[0],
            // Add more platforms as needed
          };
          this.platformLinks = platformLinks;
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>