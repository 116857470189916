<template>
    <div class="footer">
        Creature Fear is a sponsored project of Fractured Atlas, a non-profit arts service organization. Contributions for the charitable purposes of Creature Fear must be made payable to “Fractured Atlas” only and are tax-deductible to the extent permitted by law.
    </div>
</template>

<script>
export default {
  name: "FooterComponent"
};
</script>