import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TrailerView from '../views/TrailerView.vue'
import GalleryView from '../views/GalleryView.vue'
import PodcastsView from '../views/PodcastsView.vue'
import ContactView from '../views/ContactView.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      transition: 'fade',
      title: 'Creature Fear Film'
     },
  },
  {
    path: '/teaser',
    name: 'teaser',
    component: TrailerView,
    meta: {
      title: 'Teaser'
    },
  },
  {
    path: '/press',
    name: 'gallery',
    component: GalleryView,
    meta: {
      title: 'Press'
    },
  },
  {
    path: '/podcast',
    name: 'podcasts',
    component: PodcastsView,
    meta: {
      title: 'Podcast'
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: {
      title: 'Contact Us'
    },
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
