<template>
      <div class="home container-div">
        <div class="title-container">
          <p class="presented-by">Manny Gumina Presents</p>
          <h1 class="title">CREATURE<br>FEAR</h1>
          <p class="description">An unhinged Wisconsin horror film</p>
        </div>
        <img class="hero-img" src="../assets/hero-img.png" alt="hero img">
        <p class="description mobile">An unhinged Wisconsin horror film</p>
      </div>
</template>

<script>
</script>
